// AWS - Configuration file

const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_AWS_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_AWS_WEB_CLIENT,
      identityPoolId: import.meta.env.VITE_AWS_IDENTITY_POOL_ID,
      region: import.meta.env.VITE_AWS_REGION,
      authenticationFlowType: "CUSTOM_AUTH",
    },
  },
  API: {
    REST: {
      EmployerApiGw: {
        endpoint: import.meta.env.VITE_AWS_REST_API_ENDPOINT + process.env.VITE_REACT_APP_STAGE,
        region: import.meta.env.VITE_AWS_REGION,
      },
    },
  },
  Storage: {
    S3: {
      bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME,
      region: import.meta.env.VITE_AWS_REGION,
    },
  },
};

export default awsConfig;
