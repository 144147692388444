import React, { useRef, useState, Fragment } from "react";

// AWS Amplify - API - Request Handler
import { handleGet } from "../utils/amplifyInstance";

// Auth Session
import { useSession } from "../context/AuthSession";

// Material-UI
import { Tooltip, IconButton, CircularProgress } from "@mui/material";
import TableCell from "@mui/material/TableCell";

// DevExtreme React Grid
import {
  Plugin,
  Template,
  TemplatePlaceholder,
  Getter,
} from "@devexpress/dx-react-core";

// DevExtreme React Grid Material-UI
import { Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

// Material-UI Icons
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BookIcon from "@mui/icons-material/LibraryBooks";
import PaymentIcon from "@mui/icons-material/Payment";
import CloseIcon from "@mui/icons-material/Close";

// Helper Functions
import {
  DownloadFile,
  UploadFile,
  FileExists,
  PostMessage,
  validateFileType,
  validateFileSize,
  UpdateStatus,
  HasProfile,
} from "../common/HelperFunctions";

// third-party
import { format } from "date-fns";
import { useSnackbar } from "notistack";

// ================= TABLE ACTIONS COLUMN ================= //
function TableActionsColumn(props) {
  const { authToken } = useSession();
  const { account, accountid, companyid, statementid, padagreementid, roleid } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const fileInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const cognito_identity_uuid = account.map(i => i.cognito_identity_uuid)[0];

  // Action button for snackbar
  const action = (key) => (
    <Fragment>
      <IconButton
        onClick={() => {
          closeSnackbar(key);
        }}
        size="large"
      >
        <CloseIcon fontSize={"small"} />
      </IconButton>
    </Fragment>
  );

  // Upload Action for Payment
  const uploadAction = (key) => (
    <Fragment>
      <Tooltip title="Make a Payment">
        <IconButton
          size="small"
          onClick={(e) => {
            closeSnackbar(key);

            if (padagreementid) {
              e.preventDefault();
              props.setAmountReadOnly(false);
              props.setOpenPADDialog(true);
            } else {
              enqueueSnackbar(
                "Sign up to pre-authorized debits and conveniently pay through EmployerConnect. Please contact the Plan Office for more Information.",
                {
                  preventDuplicate: true,
                  persist: true,
                  variant: "info",
                  action,
                }
              );
            }
          }}
        >
          <PaymentIcon />
        </IconButton>
      </Tooltip>
      <IconButton
        onClick={() => {
          closeSnackbar(key);
        }}
        size="large"
      >
        <CloseIcon fontSize={"small"} />
      </IconButton>
    </Fragment>
  );

  // File Upload Handler
  const onFileInputChange = async (e) => {
    try {
      let result = null;
      let file = e.target.files[0];

      if (!validateFileType("memberdetail", file)) {
        enqueueSnackbar("Upload file type not allowed!", { variant: "error" });
      } else if (!validateFileSize(file)) {
        enqueueSnackbar("Attachment file size exceeds 5 MBytes!", {
          variant: "error",
        });
      } else {
        var fileExists = await FileExists(
          companyid,
          cognito_identity_uuid,
          "protected",
          file.name
        );
        if (
          !fileExists ||
          (fileExists &&
            window.confirm(
              "File with that name already exists.  Are you sure you want to overwrite it?"
            ))
        ) {
          result = await UploadFile(file, companyid, cognito_identity_uuid);
        } else {
          enqueueSnackbar("File Upload Cancelled.", { variant: "error" });
        }
      }

      if (result) {
        enqueueSnackbar("File Uploaded to server successfully", {
          variant: "success",
        });
        var hp = await HasProfile(accountid, companyid, authToken);
        if (hp.hasprofile === 1) {
          props.setSelectedFile(e.target.files[0]);
          props.setOpenUploadFileDialog(true);
        } else {
          UpdateStatus(accountid, companyid, statementid, authToken)
            .then(() => {
              props.setStatusChanged(true);
            })
            .catch((error) => {
              enqueueSnackbar("Statement Status Update failed: " + error, {
                variant: "error",
              });
            });

          var attachmentInfo = [];
          attachmentInfo.push({
            filelink: result,
          });

          var message = {
            parentid: null,
            viewedon: format(new Date(), "yyyyMMddHHmmss"),
            ownerid: parseInt(companyid),
            ownertypeid: 2,
            author: accountid.toString(),
            sender: "ERCONTACT",
            publicmessageid: null,
            attachments: attachmentInfo,
          };

          message.note =
            "Autogenerated Message: File successfully uploaded for statement ID: " +
            statementid;
          PostMessage(accountid, message, authToken)
            .then(() => {
              enqueueSnackbar("Message sent successfully!", {
                preventDuplicate: true,
                persist: true,
                variant: "success",
                action: uploadAction(),
              });
            })
            .catch((error) => {
              enqueueSnackbar("Message failed: " + error, { variant: "error" });
            });
        }
      }
    } catch (e) {
      enqueueSnackbar("File Upload to server failed!" + e.message, {
        variant: "error",
      });
    }
  };

  // Download Statement Summary Hours
  const DownloadHours = async (statementid) => {
    try {
      // var response = await get(
      //   "EmployerApiGw",
      //   "/employer/" +
      //     accountid +
      //     "/" +
      //     companyid +
      //     "/statement/" +
      //     statementid,
      //   {
      //     responseType: "blob",
      //   }
      // );
      var response = await handleGet({
        apiName: "EmployerApiGw",
        path: `/employer/${accountid}/${companyid}/statement/${statementid}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = await response;
      const blob = await body.blob();
      setIsLoading(false);

      const element = document.createElement("a");
      var file = new Blob([blob], {
        type: "application/pdf",
      });
      element.href = URL.createObjectURL(file);
      element.download = statementid + ".pdf";
      document.body.appendChild(element);
      element.click();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Fragment>
      <input
        id="file"
        type="file"
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
        ref={fileInput}
        style={{ display: "none" }}
        onChange={onFileInputChange}
      />
      <Plugin>
        <Getter
          name="tableColumns"
          computed={({ tableColumns }) => {
            const result = tableColumns.slice();
            result.splice(0, 0, {
              key: "actions",
              type: "actions",
              align: "center",
              width: 120,
            });
            return result;
          }}
        />

        <Template
          name="tableCell"
          predicate={({ tableColumn, tableRow }) =>
            tableColumn.type === "actions" && tableRow.type === Table.ROW_TYPE
          }
        >
          {(params) => (
            <TemplatePlaceholder>
              {() => (
                <TableCell align="center" padding="none">
                  <Tooltip title="Download TA">
                    <span>
                      <IconButton
                        size="small"
                        disabled={!params.tableRow.row.filename}
                        onClick={() => {
                          DownloadFile(
                            "protected/" + cognito_identity_uuid + "/" + companyid + "/planoffice/" + params.tableRow.row.filename,
                            cognito_identity_uuid
                          );
                        }}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Upload TA">
                    <span>
                      <IconButton
                        size="small"
                        disabled={
                          params.tableRow.row.status === "closed" ||
                          params.tableRow.row.hoursuploaded === "yes" ||
                          roleid === 2
                        }
                        onClick={(e) => {
                          if (statementid) {
                            fileInput.current.value = null;
                            fileInput.current.click();
                          }
                        }}
                      >
                        <CloudUploadIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Download Statement Summary">
                    <span>
                      <IconButton
                        size="small"
                        disabled={
                          params.tableRow.row.status !== "closed" ||
                          roleid === 2
                        }
                        onClick={(e) => {
                          setIsLoading(true);
                          DownloadHours(params.tableRow.row.statementid);
                        }}
                      >
                        {isLoading &&
                        params.tableRow.row.statementid === statementid ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <BookIcon />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Make a Payment">
                    <span>
                      <IconButton
                        size="small"
                        disabled={
                          params.tableRow.row.paymentexists === "Y" ||
                          params.tableRow.row.outstanding <= 0 ||
                          roleid === 2
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          if (padagreementid) {
                            e.preventDefault();
                            props.setOpenPADDialog(true);
                          } else {
                            enqueueSnackbar(
                              "Sign up to pre-authorized debits and conveniently pay through EmployerConnect. Please contact the Plan Office for more Information.",
                              {
                                preventDuplicate: true,
                                persist: true,
                                variant: "info",
                                action,
                              }
                            );
                          }
                        }}
                      >
                        <PaymentIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              )}
            </TemplatePlaceholder>
          )}
        </Template>
        <Template
          name="tableCell"
          predicate={({ tableColumn, tableRow }) =>
            tableColumn.type === "actions" &&
            tableRow.type === TableHeaderRow.ROW_TYPE
          }
        >
          <TableCell align="center" padding="none">
            <b style={{ color: "rgba(0,0,0,0.54)", fontSize: "0.75rem" }}>
              Actions
            </b>
          </TableCell>
        </Template>
      </Plugin>
    </Fragment>
  );
}

export default TableActionsColumn;
