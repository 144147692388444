import React, { useState, useEffect, useCallback, Fragment, lazy, Suspense } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet } from '../../utils/amplifyInstance';

// Auth Session
import { useSession } from '../../context/AuthSession';

// Main Context
import { useMainContext } from '../../context/MainContext';

// Hooks
import useFetchPadAgreementById from '../../hooks/useFetchPadAgreementById';

// Material UI
import {
  Paper,
  Tooltip,
  TextField,
  IconButton,
} from '@mui/material';

import { alpha, styled } from '@mui/material/styles';

// DevExtreme React Grid
import {
  RowDetailState,
  PagingState,
  CustomPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';

// DevExtreme React Grid Material-UI UI & Editing Plugins
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

// Helper Functions
import { numericBoldFormat, currencyBoldFormat } from '../../common/HelperFunctions';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Hooks
import useFetchLtdCompany from '../../hooks/useFetchLtdCompany';

// Plugins
import TableActionsColumn from '../../plugins/TableActionsColumn';
import MakePADDialog from '../payment-agreement/MakePADDialog';

// Project Imports
import CustomLoadingScreen from '../../ui-components/CustomLoadingScreen';

// Custom Components
const MemberDetails = lazy(() => import('../member/MemberDetails'));

const getRowId = (row) => row.statementid;

const classes = {
  tableStriped: 'tableStriped',
  cell: 'TableCell-cell',
};

const StyledTable = styled(Table.Table)(({ theme }) => ({
  [`&.tableStriped`]: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      marginRight: '28px',
    },
  },
}));

const TableComponent = ({ ...restProps }) => (
  <StyledTable {...restProps} className={classes.tableStriped} />
);

const StyledToggleCell = styled(Table.Cell)(({ theme }) => ({
  [`&.TableCell-cell`]: {
    '&:first-of-type': {
      paddingLeft: '8px',
    },
  },
}));

// Custom toggle cell component
const ToggleCell = ({ handleExpandedRowIdChange, ...props }) => {
  return (
    <StyledToggleCell className={classes.cell}>
      {props.expanded ? (
        <Tooltip title="Hide employee list">
          <IconButton
            size="small"
            onClick={() => {
              handleExpandedRowIdChange([]);
            }}
          >
            <ExpandLessIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Show employee list">
          <IconButton
            size="small"
            onClick={() => {
              handleExpandedRowIdChange([props.tableRow.rowId]);
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      )}
    </StyledToggleCell>
  );
};

// Custom Cell Component
const Cell = (props) => {
  if (props.column.name === 'status' && props.row.status === 'open') {
    return (
      <Table.Cell
        {...props}
        sx={{
          color: 'red',
        }}
      />
    );
  } else {
    return <Table.Cell {...props} />;
  }
};

// Numeric Formatter Provider
const NumericTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={numericBoldFormat} {...props} />
);

// Currency Formatter Provider
const CurrencyTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={currencyBoldFormat} {...props} />
);

// Table Header Content component
const TableHeaderContent = ({ column, children, classes, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: 'bold',
    }}
  >
    {children}
  </TableHeaderRow.Content>
);

// Root component
const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} />;

// Date Formatter
const DateFormatter = ({ value }) =>
  value ? (
    <TextField
      type="date"
      value={value}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  ) : (
    ''
  );

function BillingStatement() {
  const { authToken } = useSession();
  const { account, companyid } = useMainContext();

  const accountid = account.map((i) => i.accountid)[0];
  const roleid = account.map((i) => i.roleid)[0];

  // Column definitions
  const [columns] = useState([
    { title: 'Statement Id', name: 'statementid' },
    { title: 'Start Date', name: 'periodstart' },
    { title: 'End Date', name: 'periodend' },
    {
      title: 'Status',
      name: 'status',
      getCellValue: (row) =>
        row.status === 'open' && row.hoursuploaded === 'yes' ? 'in progress' : row.status,
    },
    { title: 'Pension Hours', name: 'penhrs' },
    { title: 'LTD Hours', name: 'ltdhrs' },
    {
      title: 'Pension Contrib',
      name: 'penctrb',
      getCellValue: (row) => row.penhrs * row.pensionrate,
    },
    {
      title: 'LTD Contrib',
      name: 'ltdctrb',
      getCellValue: (row) => row.ltdhrs * row.ltdrate,
    },
    {
      title: 'H&S Contrib',
      name: 'hsctrb',
      getCellValue: (row) => (row.penhrs > 0 ? row.penhrs : row.ltdhrs) * row.hsrate,
    },
    {
      title: 'Total Contrib',
      name: 'totalctrb',
      getCellValue: (row) =>
        row.penhrs * row.pensionrate +
        row.ltdhrs * row.ltdrate +
        (row.penhrs > 0 ? row.penhrs : row.ltdhrs) * row.hsrate,
    },
    { title: 'Outstanding', name: 'outstanding' },
  ]);

  // Column extensions
  const [columnExtensions] = useState([
    { columnName: 'statementid', align: 'center', width: '85px' },
    { columnName: 'periodstart', width: '100px' },
    { columnName: 'periodend', width: '100px' },
    { columnName: 'status', align: 'center', width: '85px' },
    { columnName: 'penhrs', align: 'right', width: '100px' },
    { columnName: 'ltdhrs', align: 'right', width: '100px' },
    { columnName: 'penctrb', align: 'right', width: '110px' },
    { columnName: 'ltdctrb', align: 'right', width: '110px' },
    { columnName: 'hsctrb', align: 'right', width: '110px' },
    { columnName: 'totalctrb', align: 'right', width: '110px' },
    { columnName: 'outstanding', align: 'right', width: '110px' },
  ]);

  // Currency columns
  const [currencyColumns] = useState(['penctrb', 'ltdctrb', 'hsctrb', 'totalctrb', 'outstanding']);

  // state variables
  const [data, setData] = useState([]);
  const [totalValues, setTotalValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [expandedRowId, setExpandedRowId] = useState([]);
  const [statusChanged, setStatusChanged] = useState(false);
  const [statementRow, setStatementRow] = useState({});
  const [amountReadOnly, setAmountReadOnly] = useState(true);
  const [openPADDialog, setOpenPADDialog] = useState(false);
  const [billingStatementOpen, setBillingStatementOpen] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // Billing Statement API - Total Count
  useEffect(() => {
    let cancel = false;

    if (!accountid || !companyid) {
      return;
    }

    const fetchData = async () => {
      try {
        let response = await handleGet({
          apiName: 'EmployerApiGw',
          path: '/employer/' + accountid + '/' + companyid + '/billingstatements/totalcount',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (cancel) {
          return;
        }

        const { body } = await response;
        const json = await body.json();

        setTotalCount(json.totalcount);
      } catch (e) {
        console.log(e);
        setTotalCount(0);
      }
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, [statusChanged, accountid, companyid, authToken]);

  // Billing Statement API - Fetch Billing Statements Data
  useEffect(() => {
    let cancel = false;

    if (!accountid || !companyid || !authToken) {
      return;
    }

    const fetchData = async () => {
      try {
        // let response = await get(
        //   "EmployerApiGw",
        //   "/employer/"
        //   + accountid
        //   + "/"
        //   + companyid
        //   + "/billingstatements"
        //   + "?offset="
        //   + (currentPage * pageSize)
        // );

        let response = await handleGet({
          apiName: 'EmployerApiGw',
          path: `/employer/${accountid}/${companyid}/billingstatements?offset=${
            currentPage * pageSize
          }`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        response = JSON.parse(
          JSON.stringify(json, (k, v) => {
            return k === 'edits' ? (v == null ? [] : v.split(',')) : v == null ? '' : v;
          })
        );

        setData(response.items);
        setPageSize(response.limit);

        const openItems = response.items.filter((item) => item.status === 'open');
        const periods = openItems.map((item) => ({
          statementId: item.statementid,
          periodstart: item.periodstart,
          periodend: item.periodend,
        }));
        setBillingStatementOpen(periods);
      } catch (e) {
        console.log(e);
        setData([]);
        setBillingStatementOpen([]);
        setPageSize(0);
      }

      setStatusChanged(false);
      setIsLoading(false);
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, [statusChanged, accountid, companyid, authToken, currentPage, pageSize]);

  // Billing Statement API - Fetch PAD Agreement Id
  const { padAgreementId } = useFetchPadAgreementById(accountid, companyid, authToken);

  // Billing Statement API - Fetch Total Values
  useEffect(() => {
    if (totalValues) {
      setData((prevData) => {
        return prevData.map((row) =>
          totalValues[row.statementid]
            ? {
                ...row,
                ...totalValues[row.statementid][0],
                ...totalValues[row.statementid][1],
                ...totalValues[row.statementid][2],
              }
            : row
        );
      });
    }
  }, [totalValues]);

  // Billing Statement API - Check if the company has LTD plan
  const { data: ltdCompany } = useFetchLtdCompany(accountid, companyid, authToken);

  // Handle Expanded Row Id - Show Member Details
  const handleExpandedRowIdChange = (expandedRowIds) => {
    if (expandedRowIds.length > 1) {
      expandedRowIds.shift();
    }
    setExpandedRowId(expandedRowIds);
  };

  // Custom Toggle Cell Component
  const ToggleCellBase = (props) => {
    return <ToggleCell handleExpandedRowIdChange={handleExpandedRowIdChange} {...props} />;
  };

  // Custom Table Row Component
  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        setStatementRow(row);
      }}
    />
  );

  // Custom Row Detail Component
  const RowDetail = useCallback(
    ({ row }) => {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <MemberDetails
            accountid={accountid}
            companyid={companyid}
            roleid={roleid}
            padagreementid={padAgreementId}
            ltdCompany={ltdCompany}
            statementrow={row}
            billingStatementOpen={billingStatementOpen}
            setTotalValues={setTotalValues}
            setStatusChanged={setStatusChanged}
            setOpenPADDialog={setOpenPADDialog}
            setExpandedRowId={setExpandedRowId}
            isEditable={
              roleid !== 2 && row.status === 'open' && row.hoursuploaded === 'no' ? true : false
            }
          />
        </Suspense>
      );
    },
    [accountid, companyid, roleid, padAgreementId, ltdCompany, billingStatementOpen, setTotalValues]
  );

  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingScreen text="Loading Billing Statement(s)" />
      ) : (
        <Fragment>
          <Paper sx={{ height: '826px' }}>
            <Grid rows={data} columns={columns} getRowId={getRowId} rootComponent={Root}>
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
              />
              <CustomPaging totalCount={totalCount} />
              <NumericTypeProvider for={['penhrs', 'ltdhrs']} />
              <DataTypeProvider
                for={['periodstart', 'periodend']}
                formatterComponent={DateFormatter}
              />
              <CurrencyTypeProvider for={currencyColumns} />
              <RowDetailState
                expandedRowIds={expandedRowId}
                onExpandedRowIdsChange={handleExpandedRowIdChange}
              />
              <Table
                height="auto"
                tableComponent={TableComponent}
                rowComponent={TableRow}
                columnExtensions={columnExtensions}
                cellComponent={Cell}
              />
              <TableHeaderRow contentComponent={TableHeaderContent} />
              <TableActionsColumn
                statementRow={statementRow}
                statementid={statementRow.statementid}
                statementPeriodStart={statementRow.periodstart}
                statementPeriodEnd={statementRow.periodend}
                setStatusChanged={setStatusChanged}
                padagreementid={padAgreementId}
                setAmountReadOnly={setAmountReadOnly}
                setOpenPADDialog={setOpenPADDialog}
                account={account}
                accountid={accountid}
                companyid={companyid}
                roleid={roleid}
              />
              <TableRowDetail toggleCellComponent={ToggleCellBase} contentComponent={RowDetail} />
              <PagingPanel style={{ paddingTop: '4px', paddingBottom: '4px' }} />
            </Grid>
          </Paper>
          <MakePADDialog
            open={openPADDialog}
            setOpenPADDialog={setOpenPADDialog}
            setStatusChanged={setStatusChanged}
            accountid={accountid}
            companyid={companyid}
            statementid={statementRow.statementid}
            amountreadonly={amountReadOnly}
            padagreementid={padAgreementId}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export default BillingStatement;
